export default {
  namespaced: true,
  state: {
    donateSetting: {
      id: null,
      token: null,
      json: {
        status: false,
        message_template: null,
        sound_volume: 0,
        alert_delay: 0,
        sound_url: null,
        icon_url: null,
        google_speech: false,
        google_speech_volume: 0,
        template: 'icon_top_text_bottom',
        str_filter: [
          'ngu quá', 'gà quá',
        ],
      },
    },
    topSetting: {
      loading: false,
      json: [],
    },
    targetSetting: {
      id: null,
      token: null,
      json: {
        hidden_done: false,
        title: null,
        max: 0,
      },
    },
  },
  getters: {
    donateData: state => state.donateSetting,
    targetData: state => state.targetSetting,
    topData: state => {
      state.topSetting.json = state.topSetting.json.map(item => {
        item.loading = false
        return item
      })
      console.log('state.topSetting', state.topSetting)
      return state.topSetting
    },
  },
  mutations: {
    GET_DONATE(state, data) {
      state.donateSetting = data
    },
    UPDATE_DONATE(state, data) {
      state.donateSetting = data
    },
    GENERATOR_TOKEN(state, data) {
      if (data.type === 'donate') {
        state.donateSetting = { ...state.donateSetting, token: data.payload }
      } else if (data.type === 'target') {
        state.targetSetting = { ...state.targetSetting, token: data.payload }
      } else if (data.type === 'top') {
        state.topSetting = { ...state.topSetting, token: data.payload }
      }
    },
    UPLOAD_SOUND(state, data) {
      state.donateSetting.json = { ...state.donateSetting.json, sound_url: data }
    },
    UPLOAD_IMG(state, data) {
      state.donateSetting.json = { ...state.donateSetting.json, icon_url: data }
    },
    GET_TOP(state, data) {
      state.topSetting = {
        json: data,
        loading: true,
      }
    },
    UPDATE_TOP(state, data) {
      state.topSetting = data
    },
    GET_TARGET(state, data) {
      state.targetSetting = data
    },
    UPDATE_TARGET(state, data) {
      state.targetSetting.json = data
    },
  },
  actions: {},
}
