
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            // will call the function after 2 secs.
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            }).catch(e => {
              if (e.response && e.response.status === 500) {
                window.location.href = '/login'
              }
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint,
      {
        refresh_token: this.getToken(),
      })
  }

  getTransactionHistory(e) {
    let path = '/transaction_histories'
    if (e) path = `/transaction_histories?page=${e}`
    return this.axiosIns.get(path)
  }

  donate(args) {
    return this.axiosIns.post(this.jwtConfig.donateEndpoint, {
      ...args,
    })
  }

  getDonateSetting() {
    return this.axiosIns.get('/donate_setting')
  }

  updateDonateSetting(data) {
    return this.axiosIns.post('/donate_setting', { ...data })
  }

  uploadDonateSetting(type, form, method) {
    return this.axiosIns.post(`/widgets/upload/${type}`, form, method)
  }

  getGalleries(type) {
    return this.axiosIns.get(`/files/${type}`)
  }

  testDonate(widgetId, data) {
    return this.axiosIns.post(`/widgets/${widgetId}`, {
      ...data, test: true,
    })
  }

  getAccountSetting() {
    return this.axiosIns.post('/auth/me')
  }

  updateAccountSetting(data) {
    return this.axiosIns.put('/users', data)
  }

  updateDonateFeed(data) {
    return this.axiosIns.put('/users', data)
  }

  UploadCover(e) {
    const form = new FormData()
    form.append('file', e)
    return this.axiosIns.post('/users/upload/cover', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  UploadAvatar(e) {
    const form = new FormData()
    form.append('file', e)
    return this.axiosIns.post('/users/upload/avatar', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  getStatictisc(type) {
    return this.axiosIns.get(`/statistics/${type}`)
  }

  addBank(form) {
    return this.axiosIns.post('/banks', form)
  }

  editBank(id, form) {
    return this.axiosIns.put(`/banks/${id}`, form)
  }

  delBank(id) {
    return this.axiosIns.delete(`/banks/${id}`)
  }

  addWithdrawal(data) {
    return this.axiosIns.post('/withdrawal', data)
  }

  getOrders() {
    return this.axiosIns.get('/orders')
  }

  createOrder(data) {
    return this.axiosIns.post('/orders', data)
  }

  confirmOrder(id) {
    const data = { type: 'confirm' }
    return this.axiosIns.post(`/orders/${id}`, data)
  }

  generateToken(id) {
    return this.axiosIns.post(`/donate_setting/generator_token/${id}`)
  }

  acceptWithdrawal(id) {
    return this.axiosIns.put(`/orders/accept/${id}`)
  }
  // /user panel

  getUsers(e) {
    return this.axiosIns.get(`/users?page=${e}`)
  }

  updateUsers(userId, e) {
    return this.axiosIns.put(`/users/${userId}`, e)
  }

  getWidget(type) {
    return this.axiosIns.get(`/widgets/${type}`)
  }

  updateWidget(widgetId, data) {
    return this.axiosIns.put(`/widgets/${widgetId}`, {
      ...data,
    })
  }

  generatorWidgetToken(widgetId) {
    return this.axiosIns.put(`/widgets/${widgetId}`, {
      token: true,
    })
  }
}
