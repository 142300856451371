import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  isUserLoggedIn, // , getUserData, getHomeRouteForLoggedInUser 
} from '@/auth/utils'
// import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Statistics/EchartLine.vue'),
      meta: {
        pageTitle: 'Thống Kê',
        breadcrumb: [
          {
            text: 'Thống Kê',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/account-setting/AccountSetting.vue'),
      meta: {
        pageTitle: 'Hồ sơ',
        breadcrumb: [
          {
            text: 'Hồ sơ',
            active: true,
          },
        ],
      },
    },
    {
      path: '/withdrawal/banks_list',
      name: 'withdrawal_banks_list',
      component: () => import('@/views/Banks/BanksList.vue'),
      meta: {
        pageTitle: 'Rút tiền',
        breadcrumb: [
          {
            text: 'Rút tiền',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/orders_accept',
      name: 'orders_list',
      component: () => import('@/views/panel/OrderAccept.vue'),
      meta: {
        pageTitle: 'Đơn hàng',
        breadcrumb: [
          {
            text: 'Đơn hàng',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/users',
      name: 'users_list',
      component: () => import('@/views/panel/User.vue'),
      meta: {
        pageTitle: 'Người dùng',
        breadcrumb: [
          {
            text: 'Người dùng',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/donate/cancel',
      name: 'donate_cancel',
      component: () => import('@/views/PayCancel.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/donate/notify',
      name: 'donate_notify',
      component: () => import('@/views/PaySuccess.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forot_password',
      name: 'auth-forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset_password/:token',
      name: 'auth-reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register/success',
      name: 'reg_success',
      component: () => import('@/views/auth/RegSuccess.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/:id([0-9a-z.-]+)',
      name: 'donate',
      component: () => import('@/views/Donate/DonateForm.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/transaction_histories',
      name: 'transaction_history',
      component: () => import('@/views/TransactionHistory/List.vue'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/widgets/donate_setting',
      name: 'donate_setting',
      component: () => import('@/views/Widgets/DonateSetting.vue'),
    },
    {
      path: '/widgets/donate',
      name: 'widget-donate',
      component: () => import('@/views/Widgets/Donate.vue'),
    },
    {
      path: '/widgets/top-lists',
      name: 'widget-top-lists',
      component: () => import('@/views/Widgets/TopLists.vue'),
    },
    {
      path: '/widgets/process-bar',
      name: 'widget-process-bar',
      component: () => import('@/views/Widgets/ProgressBar.vue'),
    },
    {
      path: '/widgets/donate_setting',
      name: 'donate_setting',
      component: () => import('@/views/Widgets/DonateSetting.vue'),
    },
    {
      path: '/screens/target-progress/:id',
      name: 'screens_target-progress',
      component: () => import('@/views/screens/TargetProgressBar.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/screens/donate/:id',
      name: 'screens_donate',
      component: () => import('@/views/screens/Donate.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/screens/:type(top|top_week|top_day|top_month)/:id',
      name: 'screens_top_rate',
      component: () => import('@/views/screens/Top5.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  // Redirect to login if not logged in
  if (!isLoggedIn && !to.meta.redirectIfLoggedIn) return next({ name: 'login' })

  // If logged in => not authorized
  // return next({ name: 'misc-not-authorized' })
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    // const userData = getUserData()
    // next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
